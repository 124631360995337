<template>
  <div id="project-work-order-edit">
    <div
      class="loading"
      v-if="$asyncComputed.project.updating || evaluatingTeams || $asyncComputed.projectCostCodes.updating"
    ></div>
    <div class="loaded" v-else>
      <nav class="breadcrumb">
        <ul>
          <li>
            <router-link class="p-0 m-0" to="/projects"><a>Projects</a></router-link>
          </li>
          <li>
            <router-link class="p-0 m-0" :to="`/projects/${projectId}`">
              <a>{{ project.name }} - {{ project.code }}</a>
            </router-link>
          </li>
          <li class="is-active">
            <router-link class="p-0 m-0" :to="`/projects/${projectId}/work-order/${workOrderId}`">
              <a>{{ workOrder.workOrderNumber }}</a>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="is-flex is-flex-direction-row is-align-items-center">
        <div class="mb-0 header">WA {{ workOrder.workOrderNumber }}</div>
        <b-tooltip class="ml-2 mr-3" :label="`Copy WA#${workOrder.workOrderNumber}`">
          <b-button class="is-rounded" icon-left="copy" size="is-small" @click="confirmCopy"></b-button>
        </b-tooltip>
        <b-tag :type="statusTagType" size="is-large">{{ workOrder.state.toUpperCase() }}</b-tag>
      </div>
      <project-workorder-mlh-report
        :project-id="projectId"
        :workorder-id="workOrderId"
        :running-total="runningWoTotal"
      ></project-workorder-mlh-report>
      <form class="block" @submit.prevent="save">
        <div class="mt-5 mb-5 columns is-mobile">
          <div class="column">
            <b-field>
              <b-switch v-model="workOrder.isPrevailingWage" disabled>Prevailing Wage</b-switch>
            </b-field>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-half">
            <b-field label="Date">
              <holiday-datepicker
                v-model="workOrder.date"
                :min-date="project.startDate"
                :max-date="project.endDate"
                @input="dateChanged"
                editable
                icon="calendar"
                placeholder="Click to select..."
                :disabled="isComplete || !dateUpdateEnabled"
                legend-tooltip-position="is-right"
              ></holiday-datepicker>
              <b-tooltip label="Modify Date" class="control" type="is-dark" position="is-right" multilined>
                <b-button type="is-info" icon-left="edit" @click="confirmDateEdit" />
              </b-tooltip>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Customer">
              <b-input v-model="project.customer.name" disabled></b-input>
            </b-field>
          </div>
          <div class="column">
            <div class="job-site">
              <b-field grouped>
                <b-field label="Job Site" expanded>
                  <b-select v-model="selectedJobSiteId" @input="jobSiteChanged" expanded :disabled="isComplete">
                    <option v-for="js in jobSites" :value="js.id" :key="js.id">
                      {{ js.address }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>
            </div>
          </div>
        </div>
        <div class="my-4 is-size-4">Authorization Contact</div>
        <div class="columns">
          <div class="column">
            <b-field label="Authorized Person">
              <b-select
                v-model="selectedAuthorizationContact"
                @input="assignAuthorizationContact"
                expanded
                :disabled="isComplete"
              >
                <option v-for="c in contacts" :value="c" :key="c.id">{{ c.name }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Relation to Property">
              <b-select
                v-model="selectedPropertyRelation"
                @input="assignAuthorizationContactRelation"
                expanded
                :disabled="isComplete"
              >
                <option
                  v-for="propertyRelation in propertyRelations"
                  :value="propertyRelation.value"
                  :key="propertyRelation.value"
                >
                  {{ propertyRelation.value }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns" v-if="workOrder.authorizationContact.name">
          <div class="column">
            <div class="is-size-6">
              <div class="customer-field" v-if="workOrder.authorizationContact.name">
                <span class="field-name">Name</span>
                <span class="field-value">{{ workOrder.authorizationContact.name }}</span>
              </div>
              <div class="customer-field" v-if="workOrder.authorizationContact.phone">
                <span class="field-name">Phone</span>
                <span class="field-value">{{ workOrder.authorizationContact.phone }}</span>
              </div>
              <div class="customer-field" v-if="workOrder.authorizationContact.email">
                <span class="field-name">Email</span>
                <span class="field-value">{{ workOrder.authorizationContact.email }}</span>
              </div>
              <div class="customer-field" v-if="workOrder.authorizationContact.propertyRelation">
                <span class="field-name">Property Relation</span>
                <span class="field-value">{{ workOrder.authorizationContact.propertyRelation }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <span class="is-size-4">Project Documentation</span>
          <div>
            <a
              :href="project.documentationLink"
              target="_blank"
              v-if="project.documentationLink"
              class="underline text-blue-600"
            >
              {{ project.documentationLink }}
            </a>
            <div v-else>No documentation Set</div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <work-order-products
              :work-order="workOrder"
              :is-editable="!isComplete"
              :is-addable="!isComplete"
              @productUpdate="onProductChange"
            ></work-order-products>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Job Info">
              <b-input
                type="textarea"
                validation-message="Must have at least 4 characters"
                minlength="4"
                v-model="workOrder.jobInfo"
                required
                :disabled="isComplete"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Notes to Tech">
              <b-input
                type="textarea"
                v-model="workOrder.techNotes"
                validation-message="Must have at least 4 characters"
                minlength="4"
                required
                :disabled="isComplete"
              ></b-input>
            </b-field>
          </div>
        </div>
        <comments-view
          :association-id="workOrderId"
          :displayable-types="displayableTypes"
          :comment-type="commentType"
        ></comments-view>
        <work-order-photos :work-order-id="workOrderId"></work-order-photos>
        <div class="my-4 is-size-4">Project Foreman</div>
        <div class="columns">
          <div class="column">
            <div class="is-size-5 has-text-weight-bold">
              {{ project.foreman.lastName | capitalize }}
              {{ project.foreman.firstName | capitalize }}
            </div>
            <div class="is-size-6">
              <b-tooltip v-if="project.foreman.phoneNumber" label="Call">
                <a :href="`tel:${project.foreman.phoneNumber}`">{{ project.foreman.phoneNumber }}</a>
              </b-tooltip>
              <span class="has-text-danger" v-else>Phone number is missing</span>
            </div>
          </div>
        </div>
        <div v-if="$asyncComputed.workOrder.success && !showEdit" class="cost-code-table">
          <div class="my-4 is-size-4">Cost Codes</div>
          <work-assignment-cost-code-view
            v-for="costCode in workOrder.costCodes"
            :cost-code="costCode"
            :key="costCode.id"
            @clicked-edit="inEditMode"
          />
        </div>
        <div v-else-if="showEdit">
          <work-assignment-cost-code-edit
            :cost-code="selectedCostCode"
            :workorder-id="workOrder.id"
            :date="workOrder.date"
            @cancelled="cancelCostCodeEdit"
            @saved="saveCostCodeEdit"
          />
        </div>
        <div v-if="!showEdit" class="buttons is-pulled-right mt-5">
          <b-button class="is-danger" @click="goToProjectEdit">Cancel</b-button>
          <b-tooltip label="You Must have 1 Comment and in a Committed State to Complete" class="mr-2" multilined>
            <b-button class="is-primary" :disabled="!canComplete" @click="doComplete">Complete WA</b-button>
          </b-tooltip>
          <button class="button is-success" :disabled="isComplete" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { pick, uniq, keyBy, isEmpty, omit, identity, isEqual, sortBy, findIndex, flatten, values } from 'lodash';
import { CommentType, DateService } from '@newmoon-org/shared';
import { WORK_ORDER_STATES } from '@newmoon-org/types';
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

import EmployeesService from '@/service/employees.service';
import ProjectService from '@/service/project.service';
import WorkOrderService from '@/service/workorders.service';
import CatalogService from '@/service/catalog.service';
import CustomerService from '@/service/customer.service';
import { getByCustomerId } from '@/service/jobSite.service';

import HolidayDatepicker from '@/components/HolidayDatepicker.vue';
import WorkOrderPhotos from '@/components/workorders/WorkOrderPhotos.vue';
import WorkAssignmentCostCodeView from '@/components/workorders/WorkAssignmentCostCodeView.vue';
import WorkAssignmentCostCodeEdit from '@/components/workorders/WorkAssignmentCostCodeEdit.vue';
import WorkOrderProducts from '@/components/workorders/WorkOrderProducts.vue';
import ProjectWorkorderMlhReport from '@/components/projects/ProjectWorkorderMlhReport.vue';

import CommentsView from '@/pages/CommentsView.vue';
import schedulerMixin from '@/mixins/scheduler';

const { getEndOfDay } = DateService;

export default {
  name: 'ProjectWorkOrderEdit',
  components: {
    WorkOrderPhotos,
    CommentsView,
    WorkOrderProducts,
    ProjectWorkorderMlhReport,
    HolidayDatepicker,
    WorkAssignmentCostCodeView,
    WorkAssignmentCostCodeEdit,
  },
  mixins: [schedulerMixin],
  data() {
    return {
      evaluatingTeams: false,
      previousDate: null,
      conflicts: {},
      dateUpdateEnabled: false,
      showEdit: false,
      selectedCostCode: {},
      selectedPropertyRelation: null,
      selectedAuthorizationContact: {},
      oldAuthorizationContact: {},
      selectedJobSiteId: null,
      displayableTypes: [CommentType.ACCOUNTING_WORKFLOW, CommentType.DISPATCH_WORKFLOW, CommentType.TECH],
      commentType: CommentType.TECH,
    };
  },
  computed: {
    ...mapGetters('workAssignment', ['comments']),
    statusTagType() {
      const statusMap = {
        [WORK_ORDER_STATES.PLANNED]: 'is-warning',
        [WORK_ORDER_STATES.COMMITTED]: 'is-info',
        [WORK_ORDER_STATES.FINISHED]: 'is-success',
      };
      return statusMap[this.workOrder.state] ?? '';
    },
    canComplete() {
      return this.comments.length > 0 && WORK_ORDER_STATES.COMMITTED === this.workOrder?.state;
    },
    isComplete() {
      return WORK_ORDER_STATES.FINISHED === this.workOrder?.state;
    },
    propertyRelations() {
      return [
        { value: 'owner', label: 'Owner' },
        { value: 'landlord', label: 'Landlord' },
        { value: 'propertyManager', label: 'Property Manager' },
      ];
    },
    contacts() {
      return this.project?.customer?.contacts ?? [];
    },
    isMobile() {
      return this.$isMobile();
    },
    runningWoTotal() {
      return WorkOrderService.computeWorkAssignmentMLH(this.workOrder);
    },
    startOfTheDay() {
      const value = new Date();
      value.setHours(0, 0, 0, 0);
      return value;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    workOrderId() {
      return this.$route.params.workOrderId;
    },
    isEdit() {
      return this.workOrderId !== 'new';
    },
    employeesMap() {
      return keyBy(this.employees, 'id');
    },
  },
  watch: {
    'workOrder.date': {
      handler(_, old) {
        this.previousDate = old;
      },
    },
  },
  asyncComputed: {
    jobSites: {
      async get() {
        return getByCustomerId(this.project.customer.id);
      },
      default: [],
    },
    projectCostCodes: {
      async get() {
        return this.project.activeCostCodes ? CatalogService.listByIds(this.project.activeCostCodes) : [];
      },
      default: [],
    },
    project: {
      get() {
        return this.projectId
          ? ProjectService.getById(this.projectId).then(async r => {
              this.$asyncComputed.workOrder.update();
              const project = r;
              project.customer = r.customer?.id ? await CustomerService.get(project.customer.id) : {};
              project.foreman = r.foreman?.id
                ? pick(await EmployeesService.get(project.foreman.id), ['firstName', 'lastName', 'phoneNumber']) ?? {}
                : {};
              return project;
            })
          : { customer: {}, startDate: null, endDate: null, foreman: {} };
      },
      default: { customer: {}, startDate: null, endDate: null, foreman: {} },
    },
    workOrder: {
      async get() {
        return this.isEdit && this.$asyncComputed.project.success
          ? WorkOrderService.getById(this.workOrderId).then(wo => {
              if (!wo.id) {
                this.$router.go(-1);
                return;
              }

              this.oldAuthorizationContact = wo.authorizationContact ?? {};
              this.selectedAuthorizationContact = wo.authorizationContact ?? {};
              this.selectedPropertyRelation = wo.authorizationContact?.propertyRelation ?? null;
              this.previousDate = this.workOrder.date;
              console.log(wo);
              this.selectedJobSiteId = wo?.jobSite?.id;
              return {
                ...omit(wo, ['assignedTechs', 'assignedTechsEmails', 'foreman']),
                costCodes: sortBy(this.buildCostCodes(wo), 'startDate desc'),
                state:
                  wo.state === WORK_ORDER_STATES.FINISHED
                    ? wo.state
                    : wo.date
                    ? WORK_ORDER_STATES.COMMITTED
                    : WORK_ORDER_STATES.PLANNED,
                authorizationContact: wo.authorizationContact ?? {},
              };
            })
          : {
              name: 'New Work Assignment',
              date: this.startOfTheDay,
              costCodes: [],
              authorizationContact: {},
              state: '',
            };
      },
      default: {
        date: null,
        costCodes: [],
        authorizationContact: {},
        state: '',
      },
    },
  },
  mounted() {
    this.loadEmployees();
    this.fetchWorkAssignment({ workAssignmentId: this.workOrderId });
  },
  methods: {
    ...mapActions('workAssignment', ['fetchWorkAssignment']),
    inEditMode(costCode) {
      this.showEdit = true;
      this.selectedCostCode = costCode;
    },
    cancelCostCodeEdit() {
      this.showEdit = false;
      this.selectedCostCode = {};
    },
    saveCostCodeEdit(costCode) {
      this.showEdit = false;
      this.selectedCostCode = {};
      const index = findIndex(this.workOrder.costCodes, cc => cc.id === costCode.id);
      this.workOrder.costCodes[index] = costCode;
      this.save(false);
    },
    async dateChanged() {
      this.workOrder.finishedAt = getEndOfDay(this.workOrder.date);
      this.workOrder.state = this.workOrder.date ? WORK_ORDER_STATES.COMMITTED : WORK_ORDER_STATES.PLANNED;
      await this.resetCostCodes();
      this.dateUpdateEnabled = false;
      await this.save(false);
    },
    assignAuthorizationContact(authorizationContact) {
      if (isEqual(this.workOrder.authorizationContact, authorizationContact)) {
        this.workOrder.authorizationContact = {
          propertyRelation: this.selectedPropertyRelation,
        };
        this.selectedAuthorizationContact = null;
      } else {
        this.workOrder.authorizationContact = {
          ...authorizationContact,
          propertyRelation: this.selectedPropertyRelation,
        };
      }
    },
    assignAuthorizationContactRelation(value) {
      if (this.workOrder.authorizationContact.propertyRelation === value) {
        this.workOrder.authorizationContact.propertyRelation = null;
        this.selectedPropertyRelation = null;
      } else {
        this.workOrder.authorizationContact.propertyRelation = value;
      }
    },
    confirmCopy() {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to copy the Work Assignment?`,
        closeOnConfirm: true,
        confirmText: 'Copy',
        type: 'is-warning',
        onConfirm: this.createCopy,
      });
    },
    confirmDateEdit() {
      const changeFlag = () => (this.dateUpdateEnabled = !this.dateUpdateEnabled);
      if (this.dateUpdateEnabled) {
        changeFlag();
        return;
      }

      this.$buefy.dialog.confirm({
        message: `Are you sure you want to change the Work Assignment date?<br><br><b>Beware: changing the date may lead to schedule conflicts and a potential team unassignment from some cost codes under the Work Assignment!</b>`,
        closeOnConfirm: true,
        confirmText: 'Acknowledge',
        type: 'is-danger',
        html: true,
        onConfirm: changeFlag,
      });
    },
    async createCopy() {
      const copyWo = await WorkOrderService.createCopyForProject(this.workOrder.id);
      await ProjectService.update({
        id: this.project.id,
      });

      await this.$router.push({
        name: 'project-work-order-edit',
        params: {
          projectId: this.projectId,
          workOrderId: copyWo.id,
        },
      });
    },
    jobSiteChanged(id) {
      const jobSite = this.jobSites.find(j => j.id === id);
      this.workOrder.jobSite = {
        id: jobSite.id,
        address: jobSite.address,
      };
    },
    async resetCostCodes() {
      this.workOrder.costCodes =
        this.workOrder.costCodes?.map(it => ({
          ...it,
          startDate: it.startAtTime ? WorkOrderService.joinDateWithTime(this.workOrder.date, it.startAtTime) : null,
          endDate: it.endAtTime ? WorkOrderService.joinDateWithTime(this.workOrder.date, it.endAtTime) : null,
        })) ?? [];
      this.evaluatingTeams = true;
      const conflicts = await this.computeCostCodeConflicts(this.workOrder.costCodes);
      this.evaluatingTeams = false;
      const flatConflicts = flatten(values(conflicts)).filter(identity);

      if (flatConflicts.length > 0) {
        const warning = this.buildWarningMessageForConflicts(conflicts);

        this.$buefy.dialog.confirm({
          message: `Date Change affects the following cost codes and team members:<br><p class="font-bold" style="max-height: 150px; overflow-y: auto;">${warning}</p><br><br>Do you want to proceed and unassign the team members with the conflicts?`,
          closeOnConfirm: true,
          confirmText: 'Unassign',
          type: 'is-danger',
          html: true,
          onConfirm: () => this.unassignTechs(conflicts),
          onCancel: () => this.revertDate(),
        });
      }
    },
    revertDate() {
      this.workOrder = { ...this.workOrder, date: this.previousDate };
      this.dateChanged();
    },
    unassignTechs(conflicts) {
      this.workOrder = {
        ...this.workOrder,
        costCodes:
          this.workOrder.costCodes?.map(it => {
            const affectedEmployees = new Set(conflicts[it.id]?.map(e => e.employeeId) ?? []);
            const foreman = !it.foreman?.id || affectedEmployees.has(it.foreman.id) ? {} : it.foreman;
            const assignedTechs = it.assignedTechs.filter(t => !affectedEmployees.has(t.id));
            const assignedTechsEmails = assignedTechs.map(t => this.employeesMap[t.id]?.email).filter(identity);
            return {
              ...it,
              foreman,
              assignedTechs,
              assignedTechsEmails,
            };
          }) ?? [],
      };
    },
    buildWarningMessageForConflicts(conflicts) {
      return this.workOrder.costCodes
        .map(it => {
          if (isEmpty(conflicts[it.id])) {
            return null;
          }

          return `${it.name} - ${uniq(
            conflicts[it.id].map(e => {
              const employee = this.employeesMap[e.employeeId];
              if (!employee) {
                return null;
              }

              return `${employee.lastName} ${employee.firstName}`;
            })
          )
            .filter(identity)
            .join(', ')}`;
        })
        .filter(identity)
        .join('<br>');
    },
    buildCostCodes(wo) {
      const projectCostCodesSet = new Set(this.project.activeCostCodes ?? []);
      const costCodes = [
        ...wo.costCodes.filter(
          it => projectCostCodesSet.has(it.id) || (it.startDate && dayjs(it.startDate).isBefore(dayjs(new Date())))
        ),
      ];
      const costCodesMap = keyBy(costCodes, 'id');
      this.projectCostCodes?.forEach(cc => {
        if (isEmpty(costCodesMap) || !costCodesMap[cc.id]) {
          costCodes.push({
            ...pick(cc, WorkOrderService.COST_CODE_FIELDS),
            selected: false,
            selectedInProject: true,
            assignedTechs: [],
            assignedTechsEmails: [],
            foreman: null,
            ...this.initializeCostCodeDates(wo),
          });
        }
      });
      return costCodes;
    },
    initializeCostCodeDates(wo) {
      const startAtTime = new Date();
      const endAtTime = new Date(startAtTime);
      endAtTime.setHours(startAtTime.getHours() + 1, startAtTime.getMinutes(), 0, 0);

      const startDate = wo.date ? new Date(wo.date) : null;
      startDate?.setHours(startAtTime.getHours(), startAtTime.getMinutes(), 0, 0);
      const endDate = wo.date ? new Date(wo.date) : null;
      endDate?.setHours(endAtTime.getHours(), endAtTime.getMinutes(), 0, 0);

      return {
        startAtTime,
        endAtTime,
        startDate,
        endDate,
      };
    },
    save(leavePage = true) {
      const fn = this.isEdit ? WorkOrderService.update : WorkOrderService.create;

      return fn(this.formWorkOrder())
        .then(this.notifySuccess)
        .then(() => {
          if (leavePage) {
            this.goToProjectEdit();
          } else {
            this.$asyncComputed.workOrder.update();
          }
        });
    },
    formWorkOrder() {
      return {
        project: {
          id: this.projectId,
          code: this.project.code,
        },
        id: this.workOrderId,
        ...this.workOrder,
        costCodes: this.workOrder.costCodes?.map(it => ({
          ...pick(it, WorkOrderService.COST_CODE_FIELDS),
          workorderId: this.workOrderId,
        })),
      };
    },
    notifySuccess() {
      return this.$buefy.notification.open({
        message: this.isEdit ? `Successfully Updated Work Assignment` : 'Successfully Created Work Assignment',
        type: 'is-success',
      });
    },
    onProductChange(products) {
      this.workOrder.lineItems = [...products];
    },
    goToProjectEdit() {
      return this.$router.push({
        name: 'project-edit',
        params: {
          projectId: this.projectId,
        },
      });
    },
    doComplete() {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to complete the work assignment? When a work assignment is complete you won't be able to modify it. Only comments can be added.`,
        closeOnConfirm: true,
        title: 'Complete Work Assignment!',
        confirmText: 'Complete WA',
        type: 'is-info',
        onConfirm: () => {
          this.workOrder.state = WORK_ORDER_STATES.FINISHED;
          this.save();
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
#project-work-order-edit {
  .cost-code-table {
    .work-assignment-cost-code-view:nth-child(odd) {
      background-color: whitesmoke;
    }
  }
  .customer-field {
    .field-name {
      font-weight: bold;
      margin-right: 1em;
    }
  }
}
</style>
