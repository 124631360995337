import JobsiteList from '@/pages/JobsiteList';
import JobsiteDetails from '@/pages/JobsiteDetails';

export default [
  {
    path: '/jobsites',
    name: 'jobsites',
    component: JobsiteList,
    meta: {
      requiresAuth: true,
      label: 'Jobsites Page',
    },
  },
  {
    path: '/jobsites/:id',
    name: 'jobsite-details',
    component: JobsiteDetails,
    meta: {
      requiresAuth: true,
      label: 'Jobsite Details Page',
    },
  },
];
