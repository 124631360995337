<template>
  <div class="block" id="customer">
    <nav class="breadcrumb" v-if="showBreadCrumbs">
      <ul>
        <li>
          <router-link class="p-0 m-0" :to="{ path: '/customers' }"><a>Customers</a></router-link>
        </li>
        <li class="is-active">
          <span class="p-0 ml-2">{{ customer.name || 'new' }}</span>
        </li>
      </ul>
    </nav>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="header">{{ customerId === 'new' ? 'Create' : 'Edit' }} Customer</div>
      </div>
      <div class="column is-narrow">
        <b-button v-if="customerToEdit.id && userCanMerge" @click="isMergeActive = true">Merge Customer</b-button>
      </div>
    </div>
    <select-customer-modal
      :active="isMergeActive"
      :disabled-item="disabledCustomerCheck"
      @on-close="isMergeActive = false"
      @on-select="mergeSelected"
    >
      <template #before-content>
        <b-message class="is-size-7" type="is-info">
          <strong>Note:</strong>
          <span class="ml-1">
            This action will merge two customers contacts, job sites and service work orders. This action is not
            reversable, but you will have an opportunity to review the merge before the operation is complete.
          </span>
        </b-message>
      </template>
    </select-customer-modal>
    <b-modal
      v-model="isAddContactActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :full-screen="isFullScreen"
    >
      <template #default>
        <form class="modal-card" @submit.prevent="addContact">
          <header class="modal-card-head">{{ contactIndexIsSet ? `New Contact` : `Edit Contact` }}</header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <b-field label="Name">
                  <b-input id="contact-name" v-model="contactToCreate.name"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Email">
                  <b-input id="contact-email" v-model="contactToCreate.email" type="email"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Phone Number">
                  <b-input id="contact-phone" v-model="contactToCreate.phone"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field>
                  <b-switch id="contact-primary" v-model="contactToCreate.canTextCommunication">
                    Text Communications
                  </b-switch>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button
              id="add"
              native-type="submit"
              size="is-small"
              label="Submit"
              type="is-primary"
              :disabled="!contactNameIsSet"
            ></b-button>
            <b-button id="cancel" size="is-small" label="Cancel" type="is-danger" @click="cancelContact"></b-button>
          </footer>
        </form>
      </template>
    </b-modal>
    <b-modal
      v-model="isAddJobsiteActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      :full-screen="isFullScreen"
    >
      <template #default>
        <form class="modal-card" @submit.prevent="addJobsite">
          <header class="modal-card-head">{{ jobsiteIndexIsSet ? `New Job Site` : `Edit Job Site` }}</header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <b-field label="EzCare" expanded>
                  <b-select
                    id="jobsite-ezcare"
                    v-model="jobsiteEzCare.ezCare"
                    @input="ezCareChanged"
                    expanded
                    :disabled="customerToEdit.deleted"
                  >
                    <option v-for="ezCare in ezCareTypes" :value="ezCare.value" :key="ezCare.value">
                      {{ ezCare.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="column">
                <b-field label="EZ Care Start Date">
                  <b-datepicker
                    id="jobsite-ez-care-date"
                    :disabled="!jobsiteEzCare.ezCare || customerToEdit.deleted"
                    v-model="jobsiteEzCare.ezCareStartDate"
                    icon="calendar"
                    placeholder="Click to select..."
                    trap-focus
                  ></b-datepicker>
                </b-field>
              </div>
            </div>
            <flowright-address v-model="jobsiteToCreate"></flowright-address>
          </section>
          <footer class="modal-card-foot">
            <b-button
              id="address-add"
              native-type="submit"
              size="is-small"
              :label="jobsiteIndexIsSet ? `Add` : `Save`"
              type="is-primary"
              :disabled="!newJobSiteIsValid"
            ></b-button>
            <b-button
              id="address-cancel"
              size="is-small"
              label="Cancel"
              type="is-danger"
              @click="cancelJobsite"
            ></b-button>
          </footer>
        </form>
      </template>
    </b-modal>
    <div v-if="!customerIsReadyForEdit">
      <div>Loading</div>
      <b-progress></b-progress>
    </div>
    <form class="block" v-else @submit.prevent="upsert">
      <b-message class="is-danger" v-if="customerToEdit.deleted">
        This customer is deleted and will not appear in the application.
      </b-message>
      <div class="columns" v-if="!isNew">
        <div class="column">
          <b-field>
            <b-switch id="customer-deleted" v-model="customerToEdit.deleted" :disabled="!userCanDelete">
              Deleted
            </b-switch>
          </b-field>
        </div>
      </div>
      <div class="columns" v-if="isProspectActive">
        <div class="column">
          <b-field>
            <b-switch id="customer-id" v-model="customerToEdit.isProspective">Prospective</b-switch>
          </b-field>
        </div>
        <div class="column" v-if="customerToEdit.isProspective">
          <div>Prospective Types</div>
          <b-field>
            <b-dropdown v-model="customerToEdit.prospectiveTypes" multiple>
              <template #trigger>
                <b-button icon-right="caret-down">
                  Selected ({{ customerToEdit.prospectiveTypes?.length || 0 }})
                </b-button>
              </template>

              <b-dropdown-item v-for="type in customerProspectiveTypes" :value="type" :key="type">
                <span>{{ type }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </div>
      </div>
      <div class="flex flex-row my-3" v-if="!customerToEdit.isProspective && customerToEdit.prospectiveTypes?.length">
        <div class="text-md font-bold mr-2">Old Prospective Classification</div>
        <b-field
          class="flex flex-row items-center"
          :key="index"
          v-for="(type, index) in customerToEdit.prospectiveTypes"
        >
          <b-tag class="mr-1">{{ type }}</b-tag>
        </b-field>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Name">
            <b-input
              id="customer-name"
              v-model="customerToEdit.name"
              required
              :disabled="customerToEdit.deleted"
            ></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Display As Name">
            <b-input
              id="customer-display-as-name"
              v-model="customerToEdit.displayAsName"
              required
              :disabled="customerToEdit.deleted"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Account Status" expanded>
            <b-select
              id="customer-account-status"
              v-model="customerToEdit.accountingStatus"
              expanded
              :disabled="customerToEdit.deleted"
            >
              <option v-for="status in accountingStatus" :value="status.value" :key="status.value">
                {{ status.label }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Discount Status">
            <b-select
              id="customer-discount-status"
              v-model="customerToEdit.discountStatus"
              expanded
              :disabled="customerToEdit.deleted"
            >
              <option v-for="status in discountStatus" :value="status.value" :key="status.value">
                {{ status.label }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Customer Account Notes">
            <b-input
              id="customer-account-notes"
              type="textarea"
              v-model="customerToEdit.accountNotes"
              :disabled="customerToEdit.deleted"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="p-0 m-0 columns">
        <div class="p-0 m-0 column">
          <div class="my-4 is-size-3">Contacts</div>
        </div>
        <div class="pt-5 m-0 column">
          <b-tooltip class="is-pulled-right" label="Add a new contact">
            <b-button
              class="is-small is-info"
              icon-left="plus"
              @click="isAddContactActive = true"
              :disabled="customerToEdit.deleted"
            ></b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="container" v-if="!customerToEdit.contacts.length">
        <b-message class="m-4">No Contacts</b-message>
      </div>
      <div class="line-items" v-else>
        <div
          class="p-3 m-0 columns is-gapless is-vcentered"
          v-for="(c, index) in customerToEdit.contacts"
          :key="index"
          :class="{
            'is-even': index % 2 === 0,
            'is-odd': index % 2 !== 0,
            'has-background-warning': (customerToMerge.contacts || []).includes(c),
          }"
        >
          <div class="line-item-details column">
            <div class="line-item-info">
              <div>
                <span class="field-name">Name</span>
                <span>{{ c.name }}</span>
              </div>
              <div>
                <span class="field-name">Email</span>
                <span>{{ c.email }}</span>
              </div>
              <div>
                <span class="field-name">Phone Number</span>
                <span>{{ c.phone }}</span>
              </div>
            </div>
            <div class="line-item-info">
              <div class="buttons">
                <b-button
                  class="is-small is-primary"
                  icon-left="edit"
                  @click="editContact(c, index)"
                  :disabled="customerToEdit.deleted"
                ></b-button>
                <b-button
                  class="is-small is-danger"
                  icon-left="trash"
                  @click="removeContact(c, index)"
                  :disabled="customerToEdit.deleted"
                ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="is-size-3 contact-header">Billing</div>
      <flowright-address v-model="customerToEdit.billing" :deleted="customerToEdit.deleted"></flowright-address>
      <div class="columns">
        <div class="column">
          <comments-view
            :association-id="customerId"
            :displayable-types="displayableTypes"
            :comment-type="commentType"
            :disabled="isNew"
          ></comments-view>
        </div>
      </div>
      <div class="p-0 m-0 columns">
        <div class="p-0 m-0 column">
          <div class="my-4 is-size-3">Job Sites</div>
        </div>
        <div class="pt-5 m-0 column">
          <b-tooltip class="is-pulled-right" label="Add a new jobsite">
            <b-button
              class="is-small is-info"
              id="jobsite-add"
              icon-left="plus"
              @click="isAddJobsiteActive = true"
              :disabled="customerToEdit.deleted"
            ></b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="container" v-if="!jobSites">
        <b-message class="m-4">No Job Sites</b-message>
      </div>
      <div class="line-items" v-else>
        <div
          class="p-3 m-0 columns is-gapless is-vcentered"
          v-for="(c, index) in customerToEdit.jobSites"
          :key="c.id"
          :class="{
            'is-even': index % 2 === 0,
            'is-odd': index % 2 !== 0,
            'has-background-warning': (customerToMerge.jobSites || []).includes(c),
          }"
        >
          <div class="line-item-details column">
            <div class="line-item-info">
              <div>
                <span class="field-name">Address</span>
                <span>{{ c.street }} {{ c.city }} {{ c.state }} {{ c.zip }}</span>
              </div>
              <div>
                <span class="field-name">EzCare</span>
                <span>{{ getEzCareLabel(c.ezCare) }}</span>
                <template v-if="!!c.ezCareStartDate">
                  <span class="ml-5 font-bold">Start Date:</span>
                  <span class="ml-2">{{ c.ezCareStartDate | formatDate }}</span>
                </template>
              </div>
            </div>
            <div class="line-item-info">
              <div class="buttons">
                <b-button
                  class="is-small is-primary"
                  icon-left="edit"
                  @click="editJobsite(c, index)"
                  :disabled="customerToEdit.deleted"
                ></b-button>
                <b-button
                  class="is-small is-danger"
                  icon-left="trash"
                  @click="removeJobsite(c, index)"
                  :disabled="customerToEdit.deleted"
                ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4 work-order-history">
        <div class="header is-size-3">Work Order History</div>
        <b-field label="Job Site">
          <b-select class="mb-3" v-model="jobsiteId" placeholder="Select a name">
            <option v-for="c in customerToEdit.jobSites" :key="c.id" :value="c.id">
              {{ c.street }} {{ c.city }} {{ c.state }} {{ c.zip }}
            </option>
          </b-select>
        </b-field>
        <workorder-site-history :job-site-id="jobsiteId"></workorder-site-history>
      </div>
      <div class="buttons is-pulled-right">
        <b-button @click="goBack" :disabled="!customerIsReadyForEdit">Cancel</b-button>
        <b-button native-type="submit" type="is-success" :disabled="!customerIsReadyForEdit">
          {{ customerToMerge.id ? 'Merge' : 'Save' }}
        </b-button>
        <b-tooltip
          v-if="bookingEnabled && !(customerToEdit.deleted || wasDeleted)"
          label="Book a Work Order"
          position="is-left"
        >
          <b-button class="is-primary" icon-left="play-circle" @click="saveAndBook" :disabled="customerToEdit.deleted">
            {{ isNew ? 'Create & Book' : 'Book' }}
          </b-button>
        </b-tooltip>
      </div>
    </form>
  </div>
</template>

<script>
import { isEmpty, omit, pick } from 'lodash';
import { CommentType } from '@newmoon-org/shared';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';

import CommentsService from '@/service/comments.service';
import WorkOrderService from '@/service/workorders.service';

import FlowrightAddress from '@/components/address/FlowrightAddress.vue';
import WorkorderSiteHistory from '@/components/workorders/WorkorderSiteHistory.vue';
import SelectCustomerModal from '@/components/customers/SelectCustomerModal.vue';

import CustomerService, { EZ_CARE_TYPES } from '../service/customer.service';

import CommentsView from '@/pages/CommentsView.vue';

export default {
  name: 'CustomerEditPage',
  components: {
    CommentsView,
    FlowrightAddress,
    WorkorderSiteHistory,
    SelectCustomerModal,
  },
  props: {
    bookingEnabled: {
      type: Boolean,
      default: true,
    },
    componentCustomerId: {
      type: String,
      default: null,
    },
    showBreadCrumbs: {
      type: Boolean,
      default: true,
    },
    cancelAction: {
      type: Function,
      default: null,
    },
    postSaveAction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      ezCareTypes: [...EZ_CARE_TYPES],
      commentType: CommentType.CUSTOMER,
      displayableTypes: [CommentType.CUSTOMER],
      customerToEdit: {},
      isLoading: false,
      isAddContactActive: false,
      contactToCreate: { name: '', email: '', phone: '', canTextCommunication: false },
      contactIndexToEdit: '',
      isAddJobsiteActive: false,
      wasDeleted: false,
      jobsiteEzCare: {
        ezCare: null,
        ezCareStartDate: null,
      },
      jobsiteToCreate: {
        street: '',
        city: '',
        state: '',
        zip: '',
        address: '',
        id: '',
      },
      jobsiteIndexToEdit: '',
      jobsiteId: '',
      accountingStatus: [...CustomerService.accountStatusOptions],
      discountStatus: [...CustomerService.discountStatusOptions],
      isMergeActive: false,
      customerToMerge: {},
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'hasPermission']),
    ...mapGetters('features', ['isFeatureEnabled']),
    newJobSiteIsValid() {
      return (
        this.jobsiteToCreate.city &&
        this.jobsiteToCreate.state &&
        this.jobsiteToCreate.zip &&
        this.jobsiteToCreate.street &&
        (!this.jobsiteEzCare.ezCare || !!this.jobsiteEzCare.ezCareStartDate)
      );
    },
    isProspectActive() {
      return this.isFeatureEnabled('prospectiveCustomers.active');
    },
    jobSites() {
      return this.customerToEdit?.jobSites.filter(j => j.deleted);
    },
    loggedInUser() {
      return this.user?.employee ?? {};
    },
    isNew() {
      return this.customerId === 'new';
    },
    customerId() {
      return this.componentCustomerId ?? this.$route.params.customerId;
    },
    customerIsReadyForEdit() {
      return !isEmpty(this.customerToEdit);
    },
    contactNameIsSet() {
      return !isEmpty(this.contactToCreate.name);
    },
    contactIndexIsSet() {
      return this.contactIndexToEdit === '';
    },
    jobsiteIndexIsSet() {
      return this.jobsiteIndexToEdit === '';
    },
    isFullScreen() {
      return this.$isMobile();
    },
    userCanDelete() {
      return ['hr', 'developer', 'global administrator', 'administrator'].includes(
        this.loggedInUser.workflowFunction?.name?.toLowerCase()
      );
    },
    userCanMerge() {
      return this.hasPermission('customer:write');
    },
  },
  watch: {
    'customerToEdit.name': {
      handler(val) {
        this.customerToEdit.displayAsName = val;
      },
    },
  },
  asyncComputed: {
    customer: {
      async get() {
        if (!this.customerId) {
          return {};
        }
        if (this.customerId === 'new') {
          this.customerToEdit = CustomerService.defaultCustomer();
          return { name: 'new' };
        }

        try {
          this.isLoading = true;
          const customer = await CustomerService.get(this.customerId);
          this.wasDeleted = customer.deleted;
          this.isLoading = false;
          this.customerToEdit = { ...customer };
          return customer;
        } catch (e) {
          this.notify(`There was an error loading the customer`, 'is-error');
          return {};
        }
      },
      default: {},
    },
    itemsToMerge: {
      async get() {
        const { id, jobSites, contacts } = this.customerToMerge;
        const workorders = await WorkOrderService.getByCustomerId(id);
        return { jobSites, contacts, workorders };
      },
      default: {},
      shouldUpdate() {
        return this.customerToMerge.id;
      },
    },
    customerProspectiveTypes: {
      async get() {
        return CustomerService.getProspectiveTypes();
      },
      default: [],
    },
  },
  methods: {
    getEzCareLabel(value = null) {
      return this.ezCareTypes.find(it => it.value === value).label;
    },
    async remove() {
      const { result } = await this.$buefy.dialog.confirm({
        message: 'Are you sure? You want to Delete this customer',
        closeOnConfirm: true,
      });

      if (result) {
        await CustomerService.softDelete(this.customerId);
        await this.goBack();
      }
    },
    cancelContact() {
      this.contactToCreate = { name: '', email: '', phone: '' };
      this.contactIndexToEdit = '';
      this.isAddContactActive = false;
    },
    addContact() {
      if (this.contactIndexToEdit !== '') {
        this.customerToEdit.contacts.splice(this.contactIndexToEdit, 1, this.contactToCreate);
        this.cancelContact();
        return;
      }
      this.customerToEdit.contacts.push(this.contactToCreate);
      this.contactToCreate = { name: '', email: '', phone: '' };
      this.cancelContact();
    },
    editContact(c, idx) {
      this.isAddContactActive = true;
      this.contactToCreate = c;
      this.contactIndexToEdit = idx;
    },
    async removeContact(c, idx) {
      if (!c.name) {
        //shortcut if this contact isnt saved
        this.customerToEdit.contacts.splice(idx, 1);
        return;
      }
      const { result } = await this.$buefy.dialog.confirm({
        message: 'Are you sure?',
        closeOnConfirm: true,
      });

      if (result) {
        this.customerToEdit.contacts.splice(idx, 1);
      }
    },
    cancelJobsite() {
      this.jobsiteToCreate = {
        street: '',
        city: '',
        state: '',
        zip: '',
        address: '',
        id: '',
      };
      this.jobsiteEzCare = {
        ezCare: null,
        ezCareStartDate: null,
      };
      this.jobsiteIndexToEdit = '';
      this.isAddJobsiteActive = false;
    },
    ezCareChanged() {
      if (!this.jobsiteEzCare.ezCare) {
        this.jobsiteEzCare.ezCareStartDate = null;
      }
    },
    addJobsite() {
      const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];
      let isEditing = this.jobsiteIndexToEdit !== '';
      let id = uuidv4();
      if (isEditing) {
        // if we are editing, we need to keep the same id, the flowright address component will get rid of it
        // this is bad design and we need to remove how the address component works as its not 1:1 with a jobsite
        id = this.customerToEdit.jobSites[this.jobsiteIndexToEdit].id;
        this.customerToEdit.jobSites.splice(this.jobsiteIndexToEdit, 1);
      }
      const address = `${this.jobsiteToCreate.street} ${this.jobsiteToCreate.city} ${this.jobsiteToCreate.state} ${this.jobsiteToCreate.zip}`;
      if (!this.customerToEdit.jobSites) this.customerToEdit.jobSites = [];

      const newJobSite = {
        ...this.jobsiteToCreate,
        ...this.jobsiteEzCare,
        address,
        deleted: false,
        id,
      };

      if (isEditing) {
        this.customerToEdit.jobSites = insert(this.customerToEdit.jobSites, this.jobsiteIndexToEdit, newJobSite);
      } else {
        this.customerToEdit.jobSites.push(newJobSite);
      }

      this.cancelJobsite();
    },
    editJobsite(c, idx) {
      const ezCareFields = ['ezCare', 'ezCareStartDate'];
      this.isAddJobsiteActive = true;
      this.jobsiteToCreate = omit(c, ezCareFields);
      this.jobsiteEzCare = pick(c, ezCareFields);
      this.jobsiteIndexToEdit = idx;
    },
    async removeJobsite(c, idx) {
      if (!c.name) {
        //shortcut if this contact isnt saved
        this.customerToEdit.jobSites.splice(idx, 1);
        return;
      }
      const { result } = await this.$buefy.dialog.confirm({
        message: 'Are you sure?',
        closeOnConfirm: true,
      });
      if (result) {
        this.customerToEdit.jobSites[idx].deleted = true;
      }
    },
    async saveAndBook($event) {
      const { id } = await this.save($event);
      await this.$router.push({
        name: 'customer-booking',
        params: {
          customerId: id,
          workOrderId: 'new',
        },
      });
    },
    async upsert($event) {
      const fn = this.isNew ? this.create : this.save;
      await fn($event);
    },
    async create($event) {
      const { id } = await this.save($event);
      await this.$router.push({
        name: 'customer-edit',
        params: {
          customerId: id,
        },
      });
    },
    async confirmSave(customerToMerge) {
      if (!customerToMerge.id) {
        return true;
      }
      const { jobSites, contacts, workorders } = this.itemsToMerge;
      const { result } = await this.$buefy.dialog.confirm({
        title: 'Merge Confirmation',
        message: `${jobSites.length} job site(s), ${contacts.length} contact(s), and ${workorders.length} service work order(s) will be merged into ${this.customerToEdit.name} and ${this.customerToMerge.name} will be archived.`,
        confirmText: 'Merge',
      });

      return result;
    },
    fillComputedValues() {
      this.customerToEdit.jobSites = this.customerToEdit.jobSites
        .filter(it => it.street && it.city && it.state && it.zip)
        .map(c => ({
          ...c,
          address: `${c.street} ${c.city} ${c.state} ${c.zip}`,
        }));

      this.customerToEdit.billing.address = `${this.customerToEdit.billing.street} ${this.customerToEdit.billing.city} ${this.customerToEdit.billing.state} ${this.customerToEdit.billing.zip}`;
    },
    async save(e) {
      e.preventDefault();

      this.fillComputedValues();

      if (!this.customerToEdit.contacts.length) {
        return this.$buefy.notification.open({
          message: 'You must have at least 1 contact.',
          type: 'is-danger',
        });
      }

      // confirm with user if merging
      const shouldSave = await this.confirmSave(this.customerToMerge);
      if (!shouldSave) {
        return;
      }

      // show loading since transaction can take some time
      const loadingComponent = this.$buefy.loading.open();
      const isMerging = this.customerToMerge.id;

      try {
        const { id } = await CustomerService.save(this.customerToEdit, this.customerToMerge);

        // audit record
        if (isMerging) {
          const { jobSites, contacts, workorders } = this.itemsToMerge;
          await CommentsService.create({
            associationId: this.customerToEdit.id,
            type: CommentType.CUSTOMER,
            value: `Successfully merged ${jobSites.length} job site(s), ${contacts.length} contact(s), and ${workorders.length} service work order(s) from ${this.customerToMerge.name} into ${this.customerToEdit.name}.`,
          });
        }

        this.customerToMerge = {};

        this.notify(
          isMerging
            ? `Merged ${this.customerToMerge.name} into ${this.customerToEdit.name}.`
            : `Saved Customer ${this.customerToEdit.name}`
        );
        return { id };
      } catch (e) {
        console.error(e);
        this.notify(`There was an error saving customer ${this.customerToEdit.name}, please try again.`, 'is-error');
      } finally {
        loadingComponent.close();
      }
    },
    notify(message, type = 'is-success') {
      this.$buefy.notification.open({
        message,
        type,
      });
    },
    async goBack() {
      if (!this.cancelAction) {
        await this.$router.go(-1);
      } else {
        this.cancelAction();
      }
    },
    disabledCustomerCheck(customer) {
      return customer.objectID === this.customerToEdit.id;
    },
    async mergeSelected(customerToMerge) {
      this.isMergeActive = false;
      this.customerToMerge = await CustomerService.get(customerToMerge.objectID);

      // copy contacts and jobsites
      this.customerToEdit = {
        ...this.customerToEdit,
        contacts: [...this.customerToEdit.contacts, ...this.customerToMerge.contacts],
        jobSites: [...this.customerToEdit.jobSites, ...this.customerToMerge.jobSites],
      };
    },
    highlightWorkOrdersToBeMerged() {
      // we dont need to calculate if we should add this class because
      // of how we render one history component for merging WO's and one for the existing WO's.
      return ['has-background-warning'];
    },
  },
};
</script>

<style scoped lang="scss">
#customer {
  max-width: 1600px;
}
.line-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.line-items {
  margin-bottom: 15px;
  border: #ddd solid 1px;
  border-radius: 5px;

  .is-odd {
    background-color: #eff1fa;
  }

  .line-item-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .line-item-actions {
      flex-direction: row;
      display: flex;
    }
  }
}

.field-name {
  font-weight: bold;
  margin-right: 1em;
}

.contact-header {
  margin-bottom: 0.5em;
}

.the-contact-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  align-items: center;

  .add-contact {
    margin-left: 1em;
  }
}
</style>
