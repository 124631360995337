import { render, staticRenderFns } from "./CustomerBookingPage.vue?vue&type=template&id=667c5f3e&scoped=true"
import script from "./CustomerBookingPage.vue?vue&type=script&lang=js"
export * from "./CustomerBookingPage.vue?vue&type=script&lang=js"
import style0 from "./CustomerBookingPage.vue?vue&type=style&index=0&id=667c5f3e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667c5f3e",
  null
  
)

export default component.exports