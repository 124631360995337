<template>
  <b-tooltip
    class="record-tooltip"
    append-to-body
    multilined
    size="is-large"
    type="is-white"
    :triggers="['click']"
    :auto-close="['outside']"
    @open="open"
    @close="close"
    :active="!userHasScrolled"
  >
    <slot></slot>
    <template #content>
      <strong class="is-size-7">Record Details</strong>
      <hr class="mt-0 mb-1" />
      <div class="is-size-7 has-text-grey" v-for="(value, key, index) in recordDetails" :key="index">
        <div v-if="key !== 'href'">
          <strong class="pr-1 has-text-grey">{{ key }}:</strong>
          <span>{{ value }}</span>
        </div>
      </div>
      <a v-if="recordDetails.href" :href="recordDetails.href" target="_blank">
        <span class="pr-1">Open</span>
        <b-icon icon="external-link-alt" size="is-small"></b-icon>
      </a>
    </template>
  </b-tooltip>
</template>

<script>
import WorkordersService from '@/service/workorders.service';
import CatalogService from '@/service/catalog.service';
import TimeOffService from '@/service/timeoff.service';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      recordDetails: {},
      scrollContainer: null,
      originalScrollTop: null,
      originalScrollLeft: null,
      scrollThreshold: 50,
      userHasScrolled: false,
    };
  },
  computed: {
    isWorkOrderRecord() {
      return !!this.record.workOrderId;
    },
    isTimeOffRecord() {
      return !!this.record.timeOffId;
    },
  },
  mounted() {
    this.scrollContainer = document.querySelector('.gantt-container');
  },
  methods: {
    open() {
      this.loadRecordDetails();
      this.addScrollListener();
    },
    close() {
      this.userHasScrolled = false;
      this.removeScrollListener();
    },
    async loadRecordDetails() {
      if (this.record.workOrderId) {
        const workOrder = await WorkordersService.getById(this.record.workOrderId);
        const serviceZone = await CatalogService.getById(workOrder.serviceZone.id);

        if (workOrder.project && workOrder.project.id) {
          const { href } = this.$router.resolve({
            name: 'project-work-order-edit',
            params: {
              workOrderId: this.record.workOrderId,
              projectId: workOrder.project.id,
            },
          });

          this.recordDetails = {
            'Project #': workOrder.project.code,
            'WO #': workOrder.workOrderNumber,
            'Cost Code': workOrder.costCodes
              .filter(c => c.selected === true && this.record.costCodeId === c.id)
              .map(c => c.name)
              .join(', '),
            Customer: workOrder.customer?.displayAsName,
            Address: `${workOrder.jobSite.address}`,
            href,
          };
        } else {
          const { href } = this.$router.resolve({
            name: 'manage-work-order',
            params: { workOrderId: this.record.workOrderId },
          });

          this.recordDetails = {
            'WO #': workOrder.workOrderNumber,
            Customer: workOrder.customer?.displayAsName,
            Address: workOrder.jobSite.address,
            'Service Zone': serviceZone.name,
            'Job Info': workOrder.jobInfo,
            Emergency: workOrder.isEmergency ? 'Yes' : 'No',
            'Health & Safety': workOrder.isHealthAndSafety ? 'Yes' : 'No',
            href,
          };
        }
      }

      if (this.record.timeOffId) {
        const timeoff = await TimeOffService.getById(this.record.timeOffId);
        const { href } = this.$router.resolve({
          name: 'time-off-edit',
          params: { timeOffId: this.record.timeOffId },
        });
        this.recordDetails = {
          ID: timeoff.timeOffNumber,
          Employee: timeoff.employeeName,
          Status: timeoff.status,
          href,
        };
      }
    },
    addScrollListener() {
      this.originalScrollTop = this.scrollContainer.scrollTop;
      this.originalScrollLeft = this.scrollContainer.scrollLeft;
      this.scrollContainer.addEventListener('scroll', this.scrollListener);
    },
    removeScrollListener() {
      this.scrollContainer.removeEventListener('scroll', this.scrollListener);
    },
    scrollListener() {
      const currentScrollTop = this.scrollContainer.scrollTop;
      const currentScrollLeft = this.scrollContainer.scrollLeft;
      const deltaTop = Math.abs(currentScrollTop - this.originalScrollTop);
      const deltaLeft = Math.abs(currentScrollLeft - this.originalScrollLeft);
      if (deltaTop > this.scrollThreshold || deltaLeft > this.scrollThreshold) {
        this.userHasScrolled = true;
      }
    },
  },
};
</script>

<style lang="scss">
.record-tooltip {
  .tooltip-content {
    transform: translateX(-40%) translateY(-20px) !important;
    text-align: left !important;
  }
}
</style>
