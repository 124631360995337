import { getApi } from '@/service/Api';

import { JobSite } from '@/types/JobSite';
import { PaginatedResponse } from '@/types/Pagination';

export async function getByCustomerId(customerId: string): Promise<JobSite[]> {
  const api = getApi();

  const jobSites = await api.get<JobSite[]>('/v3/job-sites', {
    params: { customerId },
  });
  return jobSites.data;
}

export async function getById(id: string): Promise<JobSite> {
  const api = getApi();

  const jobSite = await api.get<JobSite>(`/v3/job-sites/${id}`);
  return jobSite.data;
}

export async function list(jobSite: JobSite): Promise<PaginatedResponse<JobSite>> {
  const api = getApi();

  const jobSites = await api.get<PaginatedResponse<JobSite>>('/v3/job-sites', {
    params: jobSite,
  });
  return jobSites.data;
}
